import moment from 'moment';
import { SectionController } from '../SectionController';
import { ReviewPage } from '../../../services/ReviewService';
import { ItemGroupId } from '../../../models/ItemGroup';

interface InterviewReportScope extends ng.IScope {
    interviewReportForm: any;
}

class Section {
    note: any = {}
}

class InterviewReportController extends SectionController {       

    isTheInterviewer: boolean;
    isAgentInterviewer: boolean;
    isApplicant: boolean;
    isInterviewer: boolean;
    hasNote: boolean;
    displayApproveButton: boolean;
    isOfficeUser: boolean;
    editMetInPersonDate: boolean;
    page: ReviewPage;

    static override $inject = ['$rootScope', '$scope', '$q', '$interval', '$uibModal', 'dialogs', 'InterviewReport', 'interviewReport', 'agencyId', 'interviewerId', 'applicationId', 'review', 'ApproveChanges', 'Auth', 'ReevaluateSection', 'applicationObservable'];
    constructor(
        $rootScope: angular.IRootScopeService,
        $scope: InterviewReportScope,
        private $q: ng.IQService,
        private $interval: ng.IIntervalService,
        private $uibModal,
        dialogs,
        private InterviewReport,
        public interviewReport,
        private agencyId,
        private interviewerId,
        applicationId,
        review,
        private ApproveChanges,
        Auth,
        ReevaluateSection,
        applicationObservable
        ) {
        super($rootScope, $scope, Auth, ReevaluateSection, review, applicationId, ItemGroupId.InterviewReport, applicationObservable, dialogs);

        var sections = ['about', 'english', 'childcare', 'motivation', 'personality', 'evaluation', 'returner', 'medical', 'referral', 'declaration'];

        applicationObservable.subscribe(applicationInstance => {
            if (!this.interviewReport.applicationId)
                this.interviewReport.applicationId = applicationId;
            if (!this.interviewReport.interviewerId)
                this.interviewReport.interviewerId = applicationInstance.interviewerId;

            this.isTheInterviewer = this.Auth.user.roleDetails.contactId === applicationInstance.interviewerId && (applicationInstance.status == "OLA" || applicationInstance.status == "AWA" || applicationInstance.status == "TBM" && this.hasNote); 
            this.isAgentInterviewer = this.isTheInterviewer && Auth.user.isAgent;

            this.editMetInPersonDate = !(applicationInstance.status == "INA" || applicationInstance.status == "AWL" || applicationInstance.status == "APD" || applicationInstance.status == "REG");
            this.interviewReport.about.isTheInterviewer = this.isTheInterviewer;
            this.interviewReport.about.editMetInPersonDate = this.editMetInPersonDate;
            //special condition
            //Two of the interview report questions �how was the app interviewed� and �date met in person� should be made editable at PLC, the rest of the report can remain locked.
            this.interviewReport.about.isTheInterviewerAndPLC = this.Auth.user.roleDetails.contactId === applicationInstance.interviewerId && (applicationInstance.status == "OLA" || applicationInstance.status == "AWA" || applicationInstance.status == "TBM" || applicationInstance.status == "PLC");

            if (!this.isTheInterviewer || applicationInstance.status !== "OLA") {
                this.setupMetaformVersion();
            }
        })
            
        for (var i = 0; i < review.pages.length; i++) {
            if (review.pages[i].menuItem.name == "Interview Report") {
                this.hasNote = review.pages[i].hasNote;
            }
        }
            
        this.isApplicant = Auth.user.isApplicant;
        this.isInterviewer = Auth.user.isInterviewer;
        this.isOfficeUser = Auth.user.isOfficer;

        for (let section of sections) {
            if (!this.interviewReport[section]) {
                this.interviewReport[section] = new Section();
            }
        }

        this.interviewReport.motivation.isOfficeUser = this.isOfficeUser;

        this.page = review.getPage(ItemGroupId.InterviewReport);
        this.displayApproveButton = this.page && this.page.approveChange && this.isOfficeUser;

        $scope.$watchGroup([() => this.interviewReport.english.learntForYears, () => this.interviewReport.english.learntForMonths], (newValues, oldValues) => {
            this.interviewReport.english.learntForTotalMonths = ((parseInt(newValues[0]) || 0) * 12) + (parseInt(newValues[1]) || 0)
        });

        $scope.$watch(() => this.$scope.interviewReportForm.$dirty, () => {
            this.$dirty = this.$scope.interviewReportForm.$dirty
        })

        if (this.interviewReport) {
            var todayDate = moment();
            this.interviewReport.about.todaydate = function () {
                return todayDate;
            }
            var yesterdayDate = moment().add(-1, "days");
            this.interviewReport.about.yesterdayDate = function () {
                return yesterdayDate;
            }
        }

    }

    setupMetaformVersion() {
        this.interviewReport.about.requesFormVer = this.getMetaformVersion('InterviewReportAbout');
        this.interviewReport.childcare.requesFormVer = this.getMetaformVersion('InterviewReportChildcareExperience');
        this.interviewReport.personality.requesFormVer = this.getMetaformVersion('InterviewReportPersonality');
        this.interviewReport.motivation.requesFormVer = this.getMetaformVersion('InterviewReportMotivation');
        this.interviewReport.english.requesFormVer = this.getMetaformVersion('InterviewReportEnglishSkills');
        this.interviewReport.medical.requesFormVer = this.getMetaformVersion('InterviewReportMedicalCriminal');
        this.interviewReport.evaluation.requesFormVer = this.getMetaformVersion('InterviewReportEvaluation');
        this.interviewReport.declaration.requesFormVer = this.getMetaformVersion('InterviewReportDeclaration');
    }

    getMetaformVersion(name) {
        var item = this.page.items.find(i => i.menuItem.snapshotFormName === name)
        return item ? item.metaformVersion : 0;
    }

    save() {
        this.$scope.$broadcast('submitted', true);

        var promisses = [];
        var form = this.$scope.interviewReportForm;
        if (form.$dirty) {

            if (form['InterviewReportAbout']) this.interviewReport.about.metaform = form['InterviewReportAbout'].$metaformVersion;
            if (form['InterviewReportChildcareExperience']) this.interviewReport.childcare.metaform = form['InterviewReportChildcareExperience'].$metaformVersion;
            if (form['InterviewReportPersonality']) this.interviewReport.personality.metaform = form['InterviewReportPersonality'].$metaformVersion;
            if (form['InterviewReportMotivation']) this.interviewReport.motivation.metaform = form['InterviewReportMotivation'].$metaformVersion;
            if (form['InterviewReportEnglishSkills']) this.interviewReport.english.metaform = form['InterviewReportEnglishSkills'].$metaformVersion;
            if (form['InterviewReportMedicalCriminal']) this.interviewReport.medical.metaform = form['InterviewReportMedicalCriminal'].$metaformVersion;
            if (form['InterviewReportEvaluation']) this.interviewReport.evaluation.metaform = form['InterviewReportEvaluation'].$metaformVersion;
            if (form['InterviewReportDeclaration']) this.interviewReport.declaration.metaform = form['InterviewReportDeclaration'].$metaformVersion;

            //this.interviewReport.referral.metaform = form['InterviewReportAmbassadorReferral'].$metaformVersion;

            promisses.push(
                this.InterviewReport.update(this.interviewReport).$promise);
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })
            }, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            });
    }

    changesApproved() {

        var user = this.Auth.user
        var data = {
            applicationId: this.applicationId,
            item: ItemGroupId.InterviewReport,
            author: user.firstName + ' ' + user.lastName
        };

        this.ApproveChanges
            .update({ applicationId: this.applicationId }, data)
            .$promise
            .then(data => {
                if (this.displayApproveButton === true) {
                    this.displayApproveButton = false;
                    this.page.approveChange = false;
                }

                this.alerts.push({
                    type: 'success',
                    msg: 'Approval complete',
                    dismissOnTimeout: 5000
                });
            }, () => {
                this.alerts.push({
                    type: 'danger',
                    msg: 'We are sorry but this form could not be approved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            });
    }
}

angular
    .module('app')
    .controller('InterviewReportController', InterviewReportController);