import { IAlertConfig } from '../../../models/bootstrap/Alert'
import moment from 'moment';

export class usTravelRequest { 
    matchId: number
    tthfType: string
    tthfDepDate: string
    tthfDepPlace: string
    arrivalPlace: string
    arrivalPlaceTime: string 
    aPRemarks: string
    tthfDepNumber: string
    tthfRemarks: string    
    apagreementSigned: boolean
    matchStartDate: string
    hostFamily: hostFamilyData
}

export class hostFamilyData {
    homePhone: string
    hostFamilyNameLabel: string
    emailAddress: string
}

enum TravelType {
    JetTrain = "JT",
    PickUp = "PICK-UP",
    Plane = "PLANE",
    Train = "TRAIN",
}

class TravelRequestFormController {

    showMessageText: boolean = false
    alerts: IAlertConfig[] = []
    details = new usTravelRequest();
    displayReadOnly: boolean = false;
    pickUp: boolean = false;
    ticketBooked: boolean = false;
    matchStartDate: string;

    static $inject = ['$scope', '$q', '$timeout', 'Auth', 'USAuPairs', 'Spinner']
    constructor(private $scope, private $q, private $timeout, private Auth, private USAuPairs, private Spinner) {
        this.initialLoad();
    }

    initialLoad() {
        this.Spinner.show();

        this.USAuPairs.getENFlightData({
            aupairId: this.Auth.user.usAuPairId
        })
            .$promise
            .then((result) => {
                this.details = (result.data && result.data.match[0]) || new usTravelRequest();                

                this.matchStartDate = moment(this.details.matchStartDate).format('MMMM D, YYYY');
                if (this.details.tthfType && this.details.tthfType.toLowerCase() == TravelType.PickUp.toLowerCase()) {
                    this.pickUp = true;
                }
                else {

                    // If Ticket has been issued
                    if (this.details.tthfDepNumber) {
                        this.ticketBooked = true;
                        this.displayReadOnly = true;
                    }
                    // If AP has saved the form once, make form read-only.
                    else if (this.details.tthfDepPlace) {
                        this.displayReadOnly = true;
                        this.ticketBooked = false;
                    }
                }
            });

        this.Spinner.hide(); 
    }

    submit() {
        this.$scope.$broadcast('submitted', true);
        var form = this.$scope.travelRequestForm;
        if (form['USTravelRequestForm'].$valid) {
            this.Spinner.show();
            var promises = [];
            this.alerts = [];

            promises.push(this.USAuPairs.saveENFlight({ matchId: this.details.matchId }, this.details).$promise);

            this.$q.all(promises)
                .then((result) => {
                    this.$scope.travelRequestForm.$setPristine()
                    this.initialLoad();
                    this.alerts.push({
                        type: 'success',
                        msg: 'Save complete',
                        dismissOnTimeout: 3000
                    })
                    this.showMessageText = true;
                    this.$timeout(() => { this.showMessageText = false; }, 3000);
                }, (result) => {

                    var timeOut = 5000;
                    var errorMessage = 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>'

                    if (result.data.hasError) {
                        timeOut = 30000;
                        errorMessage = result.data.error[0].errors
                    }

                    this.alerts.push({
                        type: 'danger',
                        msg: errorMessage,
                        dismissOnTimeout: timeOut
                    })
                    this.showMessageText = true;
                    this.$timeout(() => { this.showMessageText = false; }, timeOut);
                })
                .finally(() => {
                    this.Spinner.hide();
                });
        }
    }
}
angular
    .module('app')
    .controller('TravelRequestFormController', TravelRequestFormController);