import moment from 'moment'
import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { EditApplicationController } from './EditApplicationController'
import { SingleDocumentUploader, DocumentUploadConfig } from '../../../controls/documentsUpload/DocumentUploadConfig';
    
class EditPersonalController extends EditApplicationController{

    //profile
    hobbyUploadConfig: any;
    aboutYou: any;

    medical: any;
    smoking: any;

    contactDetails: any;
    emergencyContact: any;
    drivingDetails: any;
    prematch: any;

    //applicationId: number
    //applicantUserId: number
    //applicationAgencyId: number
    //applicationInterviewerId: number

    certificateUploadConf: DocumentUploadConfig<SingleDocumentUploader>;

    //$dirty: boolean = false

    //alerts: IAlertConfig[] = []

    private applicationInstance;
    applicationStatus: string;

    static override $inject = ['$scope', 'applicationObservable', '$uibModal', '$q', 'dialogs', 'applicationId', 'personalDetailsPage', 'ContactDetails', 'Driving', 'EmergencyContact'
        , 'profilePage', 'ApplicationUploader', 'AboutYou', 'applicantHobbies', 'Hobby'
        , 'medicalAndCriminalPage', 'MedicalDetails', 'SmokingDetails'
        , 'travelAndProgramPage', 'Prematch','ApplicationReviewItemsAny'];
    constructor($scope, applicationObservable, private $uibModal, private $q, private dialogs, applicationId, personalDetailsPage, private ContactDetails, private Driving, private EmergencyContact
        , profilePage, ApplicationUploader, private AboutYou, private applicantHobbies, private Hobby
        , private medicalAndCriminalPage, private MedicalDetails, private SmokingDetails
        , travelAndProgramPage, private Prematch, ApplicationReviewItemsAny) {
        super($scope, applicationId, ApplicationReviewItemsAny);  

        applicationObservable.subscribe(applicationInstance => {
            this.applicationInstance = applicationInstance;
            this.applicationStatus = applicationInstance.status;
        })

        this.applicationId = applicationId;

        this.setMedicalForm($scope);

        this.aboutYou = profilePage.aboutYou;

        var oldEmail = personalDetailsPage.contact.emailAddress;
        var getCountry = () => this.contactDetails.country;

        this.contactDetails = personalDetailsPage.contact;
        this.contactDetails.getCountry = getCountry;
        this.contactDetails.oldEmail = () => oldEmail;
            
        this.emergencyContact = personalDetailsPage.emergencyContact;
        this.emergencyContact.getCountry = getCountry

        this.drivingDetails = personalDetailsPage.driving;
        this.drivingDetails.currentDate = moment().startOf('day').toDate();

        this.prematch = travelAndProgramPage.prematch;

        this.certificateUploadConf = {
            //hideDescription: true,
            uploader: new ApplicationUploader(this.applicationId, 10),
            msg: {
                upload: 'Upload Skills Certificates',
                uploaded: 'View Skills Certificates',
                approved: 'Approved Skills Certificates'

            }
        }

        $scope.$watch(() => this.$scope.personalDetailsForm.$dirty, () => {                
            this.$dirty = this.$scope.personalDetailsForm.$dirty
        })

        $scope.$watch(() => this.$scope.highlightsForm.$dirty, () => {
            this.$dirty = this.$scope.highlightsForm.$dirty
        })

        $scope.$watch(() => this.contactDetails.telephoneOne.isMobile, (val) => {
            this.checkandSetShowHostFamilyOpt();
        })
        $scope.$watch(() => this.contactDetails.telephoneTwo.isMobile, (val) => {
            this.checkandSetShowHostFamilyOpt();
        })
    }

    checkandSetShowHostFamilyOpt() {
        this.contactDetails.showHFsmsOptIn = this.contactDetails.telephoneOne.isMobile || this.contactDetails.telephoneTwo.isMobile;
    }

    save() {
        this.$scope.$broadcast('submitted', true);

        var promisses = [];
        this.alerts = [];

        var formhighlights = this.$scope.highlightsForm;
        var formPersonalDe = this.$scope.personalDetailsForm;
        var formMedical = this.$scope.medicalAndCriminalForm;
        var formAboutYou = this.$scope.highlightsForm;


        // prevent from saving if email is invalid
        if (formPersonalDe.Edit_ContactDetails.emailAddress.$invalid) {
            this.dialogs.error('Email address incorrect', 'This email address already exists in the system. Please check and enter a unique email address before you click save.')
            return;
        }

        if (formhighlights['SkillsAndInterests'].$dirty) {
            this.applicantHobbies.metaform = formhighlights['SkillsAndInterests'].$metaformVersion
            promisses.push(
                this.Hobby.update(this.applicantHobbies).$promise)
        }


        if (formMedical['Edit_MedicalAndHealth'].$dirty) {
            this.medical.metaform = formMedical['Edit_MedicalAndHealth'].$metaformVersion
            promisses.push(
                this.MedicalDetails.update(this.medical).$promise)
        }

        if (formMedical['Edit_SmokingDeclaration'].$dirty) {
            this.smoking.metaform = formMedical['Edit_SmokingDeclaration'].$metaformVersion
            promisses.push(
                this.SmokingDetails.update(this.smoking).$promise)
        }           

        if (formAboutYou['Edit_AboutYou'].$dirty) {
            this.aboutYou.metaform = formAboutYou['Edit_AboutYou'].$metaformVersion
            promisses.push(
                this.AboutYou.update(this.aboutYou).$promise)
        }

        if (formPersonalDe['Edit_ContactDetails'].$dirty) {
            this.contactDetails.metaform = formPersonalDe['Edit_ContactDetails'].$metaformVersion;
            promisses.push(
                this.ContactDetails.update(this.contactDetails).$promise.then(() =>
                {
                    this.applicationInstance.email = this.contactDetails.emailAddress

                    //TODO This should be done in a different way 
                }));
        }

        if (formPersonalDe['Edit_DrivingDetails'].$dirty) {
            this.drivingDetails.metaform = formPersonalDe['Edit_DrivingDetails'].$metaformVersion;
            promisses.push(
                this.Driving.update(this.drivingDetails).$promise);
        }

        if (formPersonalDe['Edit_EmergencyContactDetails'].$dirty) {
            this.emergencyContact.metaform = formPersonalDe['Edit_EmergencyContactDetails'].$metaformVersion;
            promisses.push(
                this.EmergencyContact.update(this.emergencyContact).$promise);
        }

        if (formPersonalDe['PreMatch'].$dirty) {
            this.prematch.metaform = formPersonalDe['PreMatch'].$metaformVersion;
            promisses.push(
                this.Prematch.update(this.prematch).$promise);
        }

        this.$q.all(promisses)
            .then(() => {
                formPersonalDe.$setPristine();
                formAboutYou.$setPristine();
                formMedical.$setPristine();

                if (!this.contactDetails.showHFsmsOptIn) {
                    this.contactDetails.hFsmsOptIn = null;
                }

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })

                this.CheckAnyReviewPending(this.applicationId);
            }, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            })
    }

    setMedicalForm(scope) {
        this.medical = this.medicalAndCriminalPage.medical;
        this.smoking = this.medicalAndCriminalPage.smoking;

        var heightFeet, heightInches
        var weightStone, weightPound

        scope.$watch(() => this.medical.height, (newVal, oldVal) => {
            var totalInches = Math.round(newVal / 2.54)
            heightFeet = Math.floor(totalInches / 12)
            heightInches = totalInches % 12

        })

        scope.$watch(() => this.medical.weight, (newVal, oldVal) => {
            var totalPounds = Math.round(newVal / 0.45359237)
            weightStone = Math.floor(totalPounds / 14)
            weightPound = totalPounds % 14

        })

        Object.defineProperties(this.medical, {
            "heightFeet": {
                get: function () {
                    return heightFeet
                },
                set: function (v) {
                    heightFeet = v
                    if (isNaN(heightInches)) { heightInches = 0; }
                    if (isNaN(parseInt(heightFeet))) { heightFeet = 0 }
                    this.height = Math.round((parseInt(heightFeet) * 12 + parseInt(heightInches)) * 2.54)
                }
            },
            "heightInches": {
                get: function () {
                    return heightInches
                },
                set: function (v) {
                    heightInches = v
                    if (isNaN(heightFeet)) { heightFeet = 0; }
                    if (isNaN(parseInt(heightInches))) { heightInches = 0 }
                    this.height = Math.round((parseInt(heightFeet) * 12 + parseInt(heightInches)) * 2.54)
                }
            },
            "weightStone": {
                get: function () {
                    return weightStone
                },
                set: function (v) {
                    weightStone = v
                    if (isNaN(weightPound)) { weightPound = 0; }
                    if (isNaN(parseInt(weightStone))) { weightStone = 0 }
                    this.weight = Math.round((parseInt(weightStone) * 14 + parseInt(weightPound)) * 0.45359237)
                }
            },
            "weightPound": {
                get: function () {
                    return weightPound
                },
                set: function (v) {
                    weightPound = v
                    if (isNaN(weightStone)) { weightStone = ""; }
                    if (isNaN(parseInt(weightPound))) { weightPound = 0 }
                    this.weight = Math.round((parseInt(weightStone) * 14 + parseInt(weightPound)) * 0.45359237)
                }
            }
        })

        this.medical.heightCentimeters = this.medical.height
        this.medical.weightKilograms = this.medical.weight

        scope.$watch(() => this.$scope.medicalAndCriminalForm.$dirty, () => {
            this.$dirty = this.$scope.medicalAndCriminalForm.$dirty
        })
    }

    editEmail() {
        this.$uibModal.open({
            templateUrl: 'areas/application/editApplication/editEmailModal.html',
            controller: 'EditEmailModalController as ctrl',
            backdrop: false,
            resolve: {
                applicationId: this.applicationId,
                email: this.contactDetails.emailAddress
            }
        }).result.then((email) => {
            this.contactDetails.emailAddress = email;
        })
    }
}

    

angular
    .module('app')
    .controller('EditPersonalController', EditPersonalController)