import { IAlertConfig } from '../../../models/bootstrap/Alert' 

interface IItepImportScope extends angular.IScope {
    iTepImportForm: any
}
export class ITepImportModalController {

    alerts: IAlertConfig[] = []
    $dirty: boolean = false;
    selectedFile
        
    static $inject = ['$uibModalInstance', 'Auth', 'Upload', '$scope', 'iTepImports', 'importfile'];
    constructor(private $uibModalInstance, private Auth, private Upload, private $scope: IItepImportScope, private iTepImports, public importfile) {
        
    }

    saveImport() {
        this.$scope.$broadcast('submitted', true);

        this.Auth.getAccessToken().then(token => {

            var form = this.$scope.iTepImportForm;

            this.Upload.upload({
                url: '/api/application/iTepImports',
                method: 'POST',
                headers: { 'Authorization': 'Bearer ' + token },
                fields: this.importfile,
                file: this.selectedFile
                //data: { username: $scope.username, file:  },
            }).then((data, status, headers, config) => {
                form.$setPristine();
                this.close();
            }, () => {
                this.alerts.push({
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 5000
                });
            }, () => {

            });

        })

        
    }

    close() {
        this.$uibModalInstance.close();
    }

    fileSelected($files, $event) {
        var extension = $files[0].name.lastIndexOf('.');
    }

    closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }
}
