import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { EditApplicationController } from './EditApplicationController'

class EditMedicalAndCriminalController extends EditApplicationController{

    medical: any
    smoking: any

    //applicationId: number

    //$dirty: boolean = false

    //alerts: IAlertConfig[] = []

    static override $inject = ['$scope', '$q', 'applicationId', 'medicalAndCriminalPage', 'MedicalDetails', 'SmokingDetails','ApplicationReviewItemsAny'];
    constructor($scope, private $q, applicationId, medicalAndCriminalPage, private MedicalDetails, private SmokingDetails, ApplicationReviewItemsAny) {
        super($scope, applicationId, ApplicationReviewItemsAny);
            
        this.applicationId = applicationId;

        this.medical = medicalAndCriminalPage.medical;
        this.smoking = medicalAndCriminalPage.smoking;

        var heightFeet, heightInches
        var weightStone, weightPound

        $scope.$watch(() => this.medical.height, (newVal, oldVal) => {
            var totalInches = Math.round(newVal / 2.54)
            heightFeet = Math.floor(totalInches / 12)
            heightInches = totalInches % 12

        })

        $scope.$watch(() => this.medical.weight, (newVal, oldVal) => {
            var totalPounds = Math.round(newVal / 0.45359237)
            weightStone = Math.floor(totalPounds / 14)
            weightPound = totalPounds % 14

        })

        Object.defineProperties(this.medical, {
            "heightFeet": {
                get: function () {
                    return heightFeet
                },
                set: function (v) {
                    heightFeet = v
                    if (isNaN(heightInches)) { heightInches = 0; }
                    if (isNaN(parseInt(heightFeet))) { heightFeet = 0 }
                    this.height = Math.round((parseInt(heightFeet) * 12 + parseInt(heightInches)) * 2.54)
                }
            },
            "heightInches": {
                get: function () {
                    return heightInches
                },
                set: function (v) {
                    heightInches = v
                    if (isNaN(heightFeet)) { heightFeet = 0; }
                    if (isNaN(parseInt(heightInches))) { heightInches = 0 }
                    this.height = Math.round((parseInt(heightFeet) * 12 + parseInt(heightInches)) * 2.54)
                }
            },
            "weightStone": {
                get: function () {
                    return weightStone
                },
                set: function (v) {
                    weightStone = v
                    if (isNaN(weightPound)) { weightPound = 0; }
                    if (isNaN(parseInt(weightStone))) { weightStone = 0 }
                    this.weight = Math.round((parseInt(weightStone) * 14 + parseInt(weightPound)) * 0.45359237)
                }
            },
            "weightPound": {
                get: function () {
                    return weightPound
                },
                set: function (v) {
                    weightPound = v
                    if (isNaN(weightStone)) { weightStone = ""; }
                    if (isNaN(parseInt(weightPound))) { weightPound = 0 }
                    this.weight = Math.round((parseInt(weightStone) * 14 + parseInt(weightPound)) * 0.45359237)
                }
            }
        })

        this.medical.heightCentimeters = this.medical.height
        this.medical.weightKilograms = this.medical.weight

        $scope.$watch(() => this.$scope.medicalAndCriminalForm.$dirty, () => {
            this.$dirty = this.$scope.medicalAndCriminalForm.$dirty
        })
    }

    save() {
        this.$scope.$broadcast('submitted', true);
        var promisses = [];
        this.alerts = [];

        var form = this.$scope.medicalAndCriminalForm;

        if (form['Edit_MedicalAndHealth'].$dirty) {
            this.medical.metaform = form['Edit_MedicalAndHealth'].$metaformVersion
            promisses.push(
                this.MedicalDetails.update(this.medical).$promise)
        }

        if (form['Edit_SmokingDeclaration'].$dirty) {
            this.smoking.metaform = form['Edit_SmokingDeclaration'].$metaformVersion
            promisses.push(
                this.SmokingDetails.update(this.smoking).$promise)
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })

                this.CheckAnyReviewPending(this.applicationId);
            }, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            })
    }
}

angular
    .module('app')
    .controller('EditMedicalAndCriminalController', EditMedicalAndCriminalController)