import { IAlertConfig } from '../../../models/bootstrap/Alert' 

export class childAgeWilling {
    aupairId: string
    isInfantQualified: boolean
    infantWillingInd: boolean
    toddlerWillingInd: boolean
    willing26ind: boolean
    willing6PlusInd: boolean
    multipleWillingInd: boolean
    handicapWillingInd: boolean
    isSubmitted: boolean
}


class ExtensionChildAgeWillingController {
    me: any;              
    showMessageText: boolean = false
    alerts: IAlertConfig[] = []
    details = new childAgeWilling();
    displayReadOnly: boolean = false;
    displayOfficeMessage: boolean = false;
    message: string;
    data: any;

    static $inject = ['$rootScope', '$scope', '$q', '$timeout', 'Auth', 'USAuPairs', 'Spinner']
    constructor(private $rootScope, private $scope, private $q, private $timeout, private Auth, private USAuPairs, private Spinner) {                    
        this.getChildWillingData();          
    }

    getChildWillingData() {
        this.Spinner.show();

        this.USAuPairs.getChildWilling({
            aupairId: this.Auth.user.usAuPairId
        })
            .$promise
            .then((result) => {                    

                this.data = result.data.extensionAppReview;
                this.displayReadOnly = (this.data.extensionApplicationReview
                    && (this.data.extensionApplicationReview.childAgeWillingSectionStatus == '3'
                    || this.data.extensionApplicationReview.childAgeWillingSectionStatus == '5'
                    || this.data.extensionApplicationReview.childAgeWillingSectionStatus == '6')
                        ) || false;

                this.details.isInfantQualified = result.data.isInfantQualified;

                this.displayOfficeMessage = (this.data.extensionApplicationReview
                    && this.data.extensionApplicationReview.childAgeWillingSectionStatus == '4') || false;

                // Only display data in Dislay-Mode
                if (this.displayReadOnly || this.displayOfficeMessage) {
                    this.details = result.data;
                }
                else if (!this.details.isInfantQualified) {
                    this.details.infantWillingInd = result.data.infantWillingInd;
                    this.details.toddlerWillingInd = result.data.toddlerWillingInd;
                }                    

                this.message = (this.data.extensionApplicationReview
                    && this.data.extensionApplicationReview.childAgeWillingSectionStatusReason)
            })
            .finally(() => {
                this.Spinner.hide();
            });       
    }

    submit() {
        this.$scope.$broadcast('submitted', true);
        var form = this.$scope.ChildAgeWillingForm;
        if (form['USChildAgeWilling'].$valid) {
            this.Spinner.show();
            var promises = [];
            this.alerts = [];

            this.details.aupairId = this.Auth.user.usAuPairId;
            this.details.isSubmitted = true;

            promises.push(this.USAuPairs.saveChildWilling({ aupairId: this.Auth.user.usAuPairId }, this.details).$promise);

            this.$q.all(promises)
                .then(() => {
                    this.$scope.ChildAgeWillingForm.$setPristine();
                    this.$rootScope.$broadcast('update:extension');
                    this.getChildWillingData();
                    this.alerts.push({
                        type: 'success',
                        msg: 'Save complete',
                        dismissOnTimeout: 3000
                    })
                    this.showMessageText = true;
                    this.$timeout(() => { this.showMessageText = false; }, 3000);
                }, () => {
                    this.alerts.push({
                        type: 'danger',
                        msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                        dismissOnTimeout: 3000
                    })
                    this.showMessageText = true;
                    this.$timeout(() => { this.showMessageText = false; }, 3000);
                })
                .finally(() => {
                    this.Spinner.hide();
                });
        }
    }
}
angular
    .module('app')
    .controller('ExtensionChildAgeWillingController', ExtensionChildAgeWillingController);