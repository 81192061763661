import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { BusinessArea } from '../../../services/AuthService'

class BasicDetailsController {
    
    agencyForGettingParent: any 
    agencyBasicDetails: any
    agencyId: number
    showMessageText: boolean = false
    oldStatusCode: boolean = false;
    isAgencyManagmentAllowed: boolean = false;

    $dirty: boolean = false
    alerts: IAlertConfig[] = []

    static $inject = ['$scope', '$state', 'Auth', '$q', 'dialogs', '$timeout', 'agencyBasicDetailsPage', 'AgencyBasicDetails', 'AgencyBasicDetailsPage'];
    constructor(private $scope, $state, private Auth, private $q, private dialogs, private $timeout, private agencyBasicDetailsPage, private AgencyBasicDetails, private AgencyBasicDetailsPage) {
        this.agencyId = agencyBasicDetailsPage.agencyId;

        this.oldStatusCode = agencyBasicDetailsPage.statusCode;
        this.agencyBasicDetails = agencyBasicDetailsPage;
        this.agencyBasicDetails.directUrl = $state.href('app.registerAgency', { agencyId: agencyBasicDetailsPage.agencyId }, { absolute: true })

        this.isAgencyManagmentAllowed = Auth.isInBusinessArea(BusinessArea.AGENCY_MANAGEMENT);

        $scope.$watch(() => this.$scope.agencyBasicDetailsForm.$dirty, () => {
            this.$dirty = this.$scope.agencyBasicDetailsForm.$dirty
        })
    }

    submit(form) {
        this.$scope.$broadcast('submitted', true);
        var promisses = [];
        this.alerts = [];

        if (form['AgencyBasicDetails'].$dirty) {
            this.agencyBasicDetails.metaform = form['AgencyBasicDetails'].$metaformVersion

            this.agencyBasicDetails.parentOwningAgencyId = this.agencyBasicDetails.parentOwningAgencyValue;
            promisses.push(
                this.AgencyBasicDetails.save({ agencyId: this.agencyBasicDetails.agencyId }, this.agencyBasicDetails).$promise)
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })
                this.showMessageText = true;
                this.$timeout(() => { this.showMessageText = false; }, 5000);

                this.refresh();
            }, () => {

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 5000
                })
                this.showMessageText = true;
                this.$timeout(() => { this.showMessageText = false; }, 5000);
            })
    }

    refresh() {
        this.AgencyBasicDetailsPage.get({ agencyId: this.agencyId }).$promise
            .then((results) => {
                this.agencyBasicDetails = results;
            }, () => {
                this.dialogs.error('Internal error', 'Error occured. Please try again after a while.')
            });
    }

    getDeactivationMessage() {
        return `You have chosen to deactivate ${this.agencyBasicDetails.agencyName} which has ${this.agencyBasicDetails.activeContacts} active contacts - continuing will deactivate all active contacts - Please confirm that you are happy to do this.`;
    }

    save() {
        var form = this.$scope.agencyBasicDetailsForm;

        var newStatusCode = form['AgencyBasicDetails'].statusCode.$modelValue;
        if (this.oldStatusCode != newStatusCode && newStatusCode == 'I') {
            this.dialogs.confirm('Agency deactivation', this.getDeactivationMessage())
                .result
                .then(() => {
                    this.submit(form);
                });
        } else {
            this.submit(form)
        }
    }
}

angular
    .module('app')
    .controller('BasicDetailsController', BasicDetailsController)