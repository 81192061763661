import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { IInvoice } from '../../../models/finance/IInvoice'
import { ViewTransactionController } from '../../home/workQueues/modals/ViewTransaction'
import { PromotionDiscountModalController } from '../editApplication/Models/PromotionDiscountModalController'

class EditAccountController { //extends auPair.areas.application.EditApplicationController {
    


    dirty: boolean = false

    isOfficer: boolean;
    selectedInvoice: IInvoice
    promotions: any[]
    alerts: IAlertConfig[] = []
    feeSheetUploadConf: any;
    paymentReceived: any;
    showMessageText: boolean = false

    static $inject = ['$rootScope', '$scope', 'Auth', '$uibModal', '$q', 'dialogs', 'Invoice', 'invoices', 'proofOfPayment', 'ApplicationUploader', 'applicationId', 'Finance', '$timeout'];
    constructor(private $rootScope, private $scope, private Auth, private $uibModal, private $q, private dialogs, private Invoice, public invoices: IInvoice[], proofOfPayment, ApplicationUploader, private applicationId, private Finance, private $timeout) {
        this.paymentReceived = proofOfPayment;
        this.isOfficer = Auth.user.isOfficer;

        //this.applicationId = applicationId;
        $scope.$watch(() => this.selectedInvoice, () => {
            //this.viewAsApplicant = false
            this.$rootScope.$broadcast('invoice:selectionChanged', this.selectedInvoice)
            this.$rootScope.$broadcast('sideMenu:enableFinance', this.selectedInvoice)

        })

        $scope.$on("invoice:changed", (event, data) => {
            Invoice.list({ applicationId: this.applicationId },(invoices) => {
                this.invoices = invoices;
                this.init();
            });
        });            

        this.init();

        this.feeSheetUploadConf = {
            uploader: new ApplicationUploader(applicationId, 19),
            hideDescription: true,
            msg: {
                upload: 'Upload Fee Sheet',
                processing: 'Show Fee Sheet',
                uploaded: 'Show Fee Sheet',
                approved: 'Approved Fee Sheet'
            }
        }

        this.$rootScope.$broadcast('sideMenu:enableFinance', this.selectedInvoice)
        $scope.$on("$destroy", () => {
            this.$rootScope.$broadcast('sideMenu:disableFinance')
        });

        $scope.$watch(() => this.$scope.paymentReceivedForm.$dirty, () => {
            this.dirty = this.$scope.paymentReceivedForm.$dirty
        });
    }

    init() {
        this.selectedInvoice = undefined;
        for (let invoice of this.invoices) {
            if (invoice.isCurrent){
                this.selectedInvoice = invoice;                    
            }
        }

        this.Invoice.applicationPromotions({ applicationId: this.applicationId }, (promotions) => {
            this.promotions = promotions;
        });
    }
    saveProofOfPayment() {
        this.$scope.$broadcast('submitted', true);
        this.alerts = [];
        var form = this.$scope.paymentReceivedForm;
        if (form['PaymentReceived'].$dirty && (form.$valid || this.paymentReceived.isProofOfPaymentReceived === false)) {
            this.paymentReceived.metaform = form['PaymentReceived'].$metaformVersion

            this.Finance.saveProofOfPayment({ applicationId: this.applicationId }, this.paymentReceived)
                .$promise
                .then(() => {
                    form.$setPristine();

                    this.alerts.push({ // TODO should I be able to display the same message twice?
                        type: 'success',
                        msg: 'Save complete',
                        dismissOnTimeout: 5000
                    });

                    this.showMessageText = true;
                    this.$timeout(() => { this.showMessageText = false; }, 5000);
                }, () => {
                    this.alerts.push({ // TODO should I be able to display the same message twice?
                        type: 'danger',
                        msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                        dismissOnTimeout: 5000
                    })
                    this.showMessageText = true;
                    this.$timeout(() => { this.showMessageText = false; }, 5000);
                });
        }

        //var data = {
        //    agencyId: this.agencyId,
        //    scheduleAcceptedBy: this.contactId,
        //    contractId: this.selectedContractId,
        //    userId: this.userId,
        //    agencyAgreement: this.selectedContract.agencyAgreement,
        //};

        //this.Finance.saveProofOfPayment({ agencyId: this.agencyId }, data)
        //    .$promise
        //    .then((results) => {
        //        this.showSuccessText = true;
        //        this.$timeout(() => { this.showSuccessText = false; window.location.reload(); }, 2000);
        //        //this.$timeout(() => { this.showSuccessText = false; this.selectedContract = results;}, 2000);
        //    }, () => {
        //        this.dialogs.error('Internal error', 'Error occured while saving your Agency Agreement. Please try again after a while.')
        //    });
    }

    viewTransaction(transaction) {
        this.$uibModal.open({
            templateUrl: 'areas/home/workQueues/modals/ViewTransactionModal.html',
            controller: ViewTransactionController,
            controllerAs: 'ctrl',
            backdrop: false,
            size: 'lg',
            resolve: {
                transaction: () => this.Finance.approvedTransaction({ transactionID: transaction.id }),
                title: () => 'View transaction'
            }
        }).result
    }

    viewPromotion(promotion) {        
        if (this.isOfficer) {
            this.$uibModal.open({
                templateUrl: 'areas/application/editApplication/Models/displayDiscountModal.html',
                controller: PromotionDiscountModalController,
                controllerAs: 'ctrl',
                backdrop: false, 
                resolve: {
                    promotion: promotion,
                    title: () => 'View Discount'
                }
            }).result;
        }
    }
}

angular
    .module('app')
    .controller('EditAccountController', EditAccountController)