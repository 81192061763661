import { IAlertConfig } from '../../../models/bootstrap/Alert' 

class ContactAddressController {
        
    contactAddress: any
    showMessageText: boolean = false

    $dirty: boolean = false
    alerts: IAlertConfig[] = []

    static $inject = ['$scope', '$q', '$timeout', 'dialogs', 'contactId', 'ContactAddressPage', 'ContactAddress', '$window'];
    constructor(private $scope, private $q, private $timeout, private dialogs, private contactId, ContactAddressPage, private ContactAddress, private $window) {

        var oldEmail = ContactAddressPage.email;

        this.contactAddress = ContactAddressPage;

        this.contactAddress.oldEmail = () => oldEmail;

        $scope.$watch(() => this.$scope.contactAddressForm.$dirty, () => {
            this.$dirty = this.$scope.contactAddressForm.$dirty
        })
    }
    save() {
        this.$scope.$broadcast('submitted', true);
        var promisses = [];
        this.alerts = [];

        var form = this.$scope.contactAddressForm;
            
        // prevent from saving if email is invalid
        if (form.ContactAddress.email.$invalid) {
            this.dialogs.error('Email address incorrect', 'This email address already exists in the system. Please check and enter a unique email address before you click save.')
            return;
        }

        if (form['ContactAddress'].$dirty) {

            this.changedFields(form);

            this.contactAddress.metaform = form['ContactAddress'].$metaformVersion
            promisses.push(
                this.ContactAddress.saveAddress({ contactId: this.contactAddress.contactId }, this.contactAddress).$promise)
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })
                this.showMessageText = true;
                this.$timeout(() => { this.showMessageText = false; }, 5000);
                this.$window.location.reload();
            }, () => {

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 5000
                })
                this.showMessageText = true;
                this.$timeout(() => { this.showMessageText = false; }, 5000);
            })
    }

    changedFields(form) {
        this.contactAddress.contactAddressFieldsChanged = [];
        
        if (form['ContactAddress'].$dirty) {
            this.contactAddress.contactAddressFieldsChanged.push("agencyContactAddressForm");
        }
    }
}

angular
    .module('app')
    .controller('ContactAddressController', ContactAddressController)