import { SectionController } from '../SectionController'
import { ReviewPage, ReviewService } from '../../../services/ReviewService'
import { ItemGroupId } from '../../../models/ItemGroup'
import { ReviewItemId } from '../../../models/ReviewItem'


export class HealthAndWellBeingController extends SectionController {

    displayApproveButton: boolean;
    isOfficeUser: boolean;
    isApplicant: boolean;
    medicalUploadState: any;
    criminalUploadState: any;
    page: ReviewPage;
    applicationStatus: string;
    
    static override $inject = ['$rootScope', '$scope', '$q', 'dialogs',
        'Allergies', 'PhysicalHealth', 'MentalHealth', 'MedicalTreatments', 'OtherHealthConditions', 'RestrictedHealthInformation', 'Vaccinations',
        'allergies', 'physicalHealth', 'mentalHealth', 'medicalTreatments', 'otherHealthConditions', 'restrictedHealthInformation', 'vaccinations',
        'applicationId', 'review', 'ApproveChanges', 'Auth', 'ReevaluateSection', 'applicationObservable', 'HealthReviewStatus']
    constructor($rootScope: angular.IRootScopeService, $scope: angular.IScope, private $q: ng.IQService, dialogs,
        private Allergies, private PhysicalHealth, private MentalHealth, private MedicalTreatments, private OtherHealthConditions, private RestrictedHealthInformation, private Vaccinations,
        private allergies, private physicalHealth, private mentalHealth, private medicalTreatments, private otherHealthConditions, private restrictedHealthInformation, private vaccinations,
        applicationId, review, private ApproveChanges, Auth, ReevaluateSection, applicationObservable, private HealthReviewStatus) {
        super($rootScope, $scope, Auth, ReevaluateSection, review, applicationId, ItemGroupId.HealthAndWellBeing, applicationObservable, dialogs)
            
        this.isOfficeUser = Auth.user.isOfficer;
        this.isApplicant = Auth.user.isApplicant;

        this.page = review.getPage(ItemGroupId.HealthAndWellBeing);
        this.displayApproveButton = this.page && this.page.approveChange && this.isOfficeUser;

        $scope.$watchGroup([
            () => this.$scope.allergiesForm.$dirty,
            () => this.$scope.physicalHealthForm.$dirty,
            () => this.$scope.mentalHealtForm.$dirty,
            () => this.$scope.medicalTreatmentsForm.$dirty,
            () => this.$scope.otherConditionsForm.$dirty,
            () => this.$scope.restrictedHealthForm.$dirty,
            () => this.$scope.vaccinationsForm.$dirty
        ], () => {
            this.$dirty =
                this.$scope.allergiesForm.$dirty ||
                this.$scope.physicalHealthForm.$dirty ||
                this.$scope.mentalHealtForm.$dirty ||
                this.$scope.medicalTreatmentsForm.$dirty ||
                this.$scope.otherConditionsForm.$dirty ||
                this.$scope.restrictedHealthForm.$dirty ||
                this.$scope.vaccinationsForm.$dirty;
        })

    }


    save() {
        this.$scope.$broadcast('submitted', true)

        var promisses = []
        //if (form['MedicalAndHealth'].$dirty) {
        //    this.medical.metaform = form['MedicalAndHealth'].$metaformVersion
        //    // medicalAidConfirmation is considered as invalid when option 'no' is selected
        //    // angularjs doesn't bind value to model when value is invalid
        //    // this hack is needed to post false when 'no' is selected otherwise medicalAidConfirmation is undefined
        //    if (form['MedicalAndHealth'].medicalAidConfirmation.$viewValue == false) {
        //        this.medical.medicalAidConfirmation = false;
        //    }
        //    promisses.push(
        //        this.MedicalDetails.update(this.medical)
        //            .$promise
        //            .then(() => this.sectionChanged(ReviewItemId.MedicalAndHealth))
        //    )
        //}
            
        //if (form['PreExistingMedicalConditions'].$dirty) {
        //    this.preExistingMedicalConditions.metaform = form['PreExistingMedicalConditions'].$metaformVersion
        //    // preExistingMedicalConditionsConfirmation is considered as invalid when option 'no' is selected
        //    // angularjs doesn't bind value to model when value is invalid
        //    // this hack is needed to post false when 'no' is selected otherwise preExistingMedicalConditionsConfirmation is undefined
        //    if (form['PreExistingMedicalConditions'].preExistingMedicalConditionsConfirmation.$viewValue == false) {
        //        this.preExistingMedicalConditions.preExistingMedicalConditionsConfirmation = false;
        //    }
        //    promisses.push(
        //        this.PreExistingMedicalConditionsDetails.update(this.preExistingMedicalConditions)
        //            .$promise
        //            .then(() => this.sectionChanged(ReviewItemId.PreExistingMedicalConditions))
        //    )
        //}

        if (this.$scope.allergiesForm['Allergies'].$dirty) {
            this.allergies.metaform = this.$scope.allergiesForm['Allergies'].$metaformVersion
            promisses.push(
                this.Allergies.update(this.allergies)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.Allergies))
            )
        }

        if (this.$scope.physicalHealthForm['PhysicalHealth'].$dirty) {
            this.physicalHealth.metaform = this.$scope.physicalHealthForm['PhysicalHealth'].$metaformVersion
            promisses.push(
                this.PhysicalHealth.update(this.physicalHealth)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.PhysicalHealth))
            )
        }

        if (this.$scope.mentalHealtForm['MentalHealth'].$dirty) {
            this.mentalHealth.metaform = this.$scope.mentalHealtForm['MentalHealth'].$metaformVersion
            promisses.push(
                this.MentalHealth.update(this.mentalHealth)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.MentalHealth))
            )
        }

        if (this.$scope.medicalTreatmentsForm['MedicalTreatments'].$dirty) {
            this.medicalTreatments.metaform = this.$scope.medicalTreatmentsForm['MedicalTreatments'].$metaformVersion
            promisses.push(
                this.MedicalTreatments.update(this.medicalTreatments)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.MedicalTreatments))
            )
        }

        if (this.$scope.otherConditionsForm['OtherHealthConditions'].$dirty) {
            this.otherHealthConditions.metaform = this.$scope.otherConditionsForm['OtherHealthConditions'].$metaformVersion
            promisses.push(
                this.OtherHealthConditions.update(this.otherHealthConditions)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.OtherHealthConditions))
            )
        }

        if (this.$scope.restrictedHealthForm['RestrictedHealthInformation'].$dirty) {
            this.restrictedHealthInformation.metaform = this.$scope.restrictedHealthForm['RestrictedHealthInformation'].$metaformVersion
            promisses.push(
                this.RestrictedHealthInformation.update(this.restrictedHealthInformation)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.RestrictedHealthInformation))
            )
        }

        if (this.$scope.vaccinationsForm['Vaccinations'].$dirty) {
            this.vaccinations.metaform = this.$scope.vaccinationsForm['Vaccinations'].$metaformVersion
            promisses.push(
                this.Vaccinations.update(this.vaccinations)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.Vaccinations))
            )
        }

        this.$q.all(promisses)
            .then(() => 
            {
                
                this.$scope.allergiesForm.$setPristine();
                this.$scope.physicalHealthForm.$setPristine();
                this.$scope.mentalHealtForm.$setPristine();
                this.$scope.medicalTreatmentsForm.$setPristine();
                this.$scope.otherConditionsForm.$setPristine();
                this.$scope.restrictedHealthForm.$setPristine();
                this.$scope.vaccinationsForm.$setPristine();
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })
            }, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            });
    }

    changesApproved() {

        var user = this.Auth.user
        var data = {
            applicationId: this.applicationId,
            item: ItemGroupId.HealthAndWellBeing,
            author: user.firstName + ' ' + user.lastName
        };

        this.ApproveChanges
            .update({ applicationId: this.applicationId }, data)
            .$promise
            .then(data => {
                if (this.displayApproveButton === true) {
                    this.displayApproveButton = false;
                    this.page.approveChange = false;
                }

                this.alerts.push({
                    type: 'success',
                    msg: 'Approval complete',
                    dismissOnTimeout: 5000
                });
            }, () => {
                this.alerts.push({
                    type: 'danger',
                    msg: 'We are sorry but this form could not be approved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            });
    }
}

angular
    .module('app')
    .controller('HealthAndWellBeingController', HealthAndWellBeingController)
