import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { BusinessArea } from '../../../services/AuthService';
import { MaintainAgreementsModalController } from './MaintainAgreementsModalController';
import _ from 'lodash-es';


interface WorkQueueParams {
    page: number;
    pageSize: number;
    orderBy: string;
    descending: boolean;
}

export class MaintainAgreementsController {
    messages;

    editFrom;

    selectedAgreement;

    alerts: IAlertConfig[] = []
    $dirty: boolean;

    public agreementPage: {agreements: any[], totalCount:number};
    params: WorkQueueParams;
    loading: boolean = false;
    agencyId: number;

    selections: any[];
    selectedGridItems: any[];
    allSelected: boolean;

    pageSizes: number[] = [50, 100, 500, 1000];
    lastPage: number;


    static $inject = ['$scope', 'Auth', '$q', '$state', 'MaintainAgreements', 'dialogs', '$uibModal', 'Agency', 'GridExport'];
    constructor(private $scope, private Auth, private $q, private $state, private MaintainAgreements, private dialogs, private $uibModal, private Agency, private GridExport) {

        this.selectedGridItems = [];

        this.params = {
            page: 0,
            pageSize: 50,
            orderBy: 'id',
            descending: true
        };

        $scope.$watch(() => this.selectedGridItems, (values) => {
            if (values.length > 0 && values[0]) {
                this.editAgreement(values[0]);
                this.selectedGridItems = [];
            }
        }, true); 

    }

    getAgreement(currentPage, pageItems, filterBy, filterByFields, orderBy, descending) {
        this.params.page = currentPage;
        this.params.pageSize = pageItems;
        if (orderBy) {
            this.params.orderBy = orderBy;
            this.params.descending = descending;
        } else {
            this.params.orderBy = 'id';
            this.params.descending = true;
        }

        this.refresh();
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.agreementPage.agreements) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.agreementPage.agreements) {
                entry.checked = false;
            }
        }
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let search = {
                id: item.id
            };
            if (!_.includes(this.selections, search)) {
                this.selections.push(item);

                for (let entry of this.agreementPage.agreements) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value.id === item.id);
        }
    }


    refresh() {
        this.loading = true;

        new this.MaintainAgreements.query(this.params).$promise.then((result) => {
            this.agreementPage = result;

            this.lastPage = Math.floor(this.agreementPage.totalCount / this.params.pageSize);

            this.selections = [];

            this.loading = false;
            this.$scope.$broadcast("grid_refresh");
        }, () => {
            this.loading = false;
        });
    }
        
    selectAgreement(agreement) {
        this.selectedAgreement = (this.selectedAgreement == agreement) ? undefined : agreement;            
    }

    addAgreement() {
        this.$uibModal.open({
            templateUrl: 'areas/tools/maintainAgreements/maintainAgreementsModal.html',
            controller: MaintainAgreementsModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            size: 'lg',
            resolve: {
                agreement: { id: 0, isPublished: false }
            }
        }).result.then(() => {
            this.refresh();
        });
    }

    editAgreement(agreement) {
        var currentDate = new Date();

        var isPublished = (agreement) ? agreement.isPublished : this.selections[0].isPublished;
        var agreementId = (agreement) ? agreement.id : this.selections[0].id;
        var editMode = false;
        if (!isPublished) {
            editMode = true;            
        }

        this.$uibModal.open({
            templateUrl: 'areas/tools/maintainAgreements/maintainAgreementsModal.html',
            controller: MaintainAgreementsModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            size: 'lg',
            resolve: {
                agreement: this.MaintainAgreements.get({ id: agreementId }).$promise,
                editMode: editMode
            }
        }).result.then(() => {
            this.refresh();
        });
    }

    deleteAgreement() {
        if (!this.selections[0].isPublished) {
            this.dialogs.confirm('Please Confirm', 'Are you sure that you wish to delete selected agreement?')
                .result
                .then(() => {
                    this.MaintainAgreements.deleteAgreement({ id: this.selections[0].id })
                        .$promise
                        .then(
                            () => {
                                this.alerts.push({
                                    type: 'success',
                                    msg: 'Agreement deleted successfully',
                                    dismissOnTimeout: 5000
                                });
                                this.refresh();
                            },
                            () => {
                                this.alerts.push({
                                    type: 'danger',
                                    msg: 'We are sorry but selected agreement cannot be deleted. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                                    dismissOnTimeout: 5000
                                });
                            }
                        );
                });
        }
    }

    previewAgreement() {
        if (this.selections.length == 1) {
            var url = this.$state.href('app.tools.agreementPreview', { id: this.selections[0].id });
            if (url) {
                window.open(url, '_blank');
            }
        }
    }

    public export() {
        this.GridExport.saveAsCSV('.applicationsTable', 'export.csv', this.selections.length != 0)
    }

    public closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }

    changePageSize() {
        this.lastPage = Math.floor(this.agreementPage.totalCount / this.params.pageSize);
        this.refresh();
    }

    goFirstPage() {
        if (this.params.page > 0) {
            this.params.page = 0;
        }
    }
    goPrevPage() {
        if (this.params.page > 0) {
            this.params.page = this.params.page - 1;
        }
    }
    goNextPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.params.page + 1;
        }
    }
    goLastPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.lastPage;
        }
    }
}


angular
    .module('app')
    .controller('MaintainAgreementsController', MaintainAgreementsController);