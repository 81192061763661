import { IAlertConfig } from '../../../models/bootstrap/Alert' 

class LocalFeeAgreementController {
    agencyId: number

    alerts: IAlertConfig[] = [];
    agencyContractFees: any[];
    agencyTerritoryCurrencyList: any[];
    agencyContract: any;
    canEditable: boolean;
    canAcceptContract: boolean;
    isOfficeUser: boolean;

    static $inject = ['$scope', '$rootScope', 'Auth', 'dialogs', 'AgencyContract', 'AgencyContractFees'];
    constructor(private $scope, private $rootScope, private Auth, private dialogs, private AgencyContract, private AgencyContractFees) {
        this.agencyId = Auth.user.roleDetails.agencyId;
        this.isOfficeUser = Auth.user.isOfficer;
        this.loadContract();

        AgencyContract.getContractInformationForMenu({ agencyId: this.agencyId}, (result) => {
            this.canAcceptContract = result.canAcceptContract;
        });
    }

    displaySuccessMessage() {
        this.$scope.agencyTerrFeeForm.$setPristine();

        //TODO move this to parent class and remove saved flag
        this.alerts.push({ // TODO should I be able to display the same message twice?
            type: 'success',
            msg: 'Thank you for submitting your fees',
            dismissOnTimeout: 5000
        });
    }

    displayErrorMessage() {

        this.alerts.push({ // TODO should I be able to display the same message twice?
            type: 'danger',
            msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
            dismissOnTimeout: 5000
        });
    }

    loadContract() {
        this.AgencyContract.getLatestAgencyContract({ agencyId: this.agencyId })
            .$promise
            .then((r) => {
                this.agencyContract = r;
                this.canEditable = this.agencyContract && !this.agencyContract.isAgentInformationSubmited
                this.loadContractFees();
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while getting Agency Contract. Please try again after a while.')
            });
    }

    loadContractFees() {
        this.AgencyContractFees.query({ agencyId: this.agencyContract.agencyId, contractId: this.agencyContract.id})
            .$promise
            .then((r) => {
                this.agencyContractFees = r;
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while getting Agency Contract Fees. Please try again after a while.')
            })
    }
        
    save(submitFees) {
        if (this.$scope.agencyTerrFeeForm.$dirty) {
            this.$scope.$broadcast('submitted', true);

            if (this.Auth.user.isOfficer) {
                if (!this.agencyContract.isAccepted && this.agencyContract.isAgentInformationSubmited) {
                    this.saveContractFees(submitFees );
                }
            } else {
                this.saveContractFees(submitFees );
            }            
        }
    }

    saveContractFees(submitFees ) {
        this.AgencyContractFees.save({ agencyId: this.agencyContract.agencyId, contractId: this.agencyContract.id }, this.agencyContractFees)
            .$promise
            .then(() => {
                //this.displaySuccessMessage();
                this.$scope.agencyTerrFeeForm.$setPristine();

                if (!submitFees) {
                    this.alerts.push({
                        type: 'success',
                        msg: 'Saved Successfully',
                        dismissOnTimeout: 5000
                    });
                    this.loadContract();
                }
                else {
                    this.submitFees(true);
                }
            }, () => {
                this.displayErrorMessage();
            });
    }

    saveAndSubmitFees() {
        var localfeeToSave = this.$scope.agencyTerrFeeForm.$dirty;
        var confirmMessageText;

        if (localfeeToSave) {
            confirmMessageText = 'Do you want to save and submit your Local Fees? You will not be able to edit Local Fees after submitting';
        }
        else {
            confirmMessageText = 'Do you want to submit your Local Fees? You will not be able to edit Local Fees after submitting';
        }

        this.dialogs.confirm('Confirm', confirmMessageText)
            .result
            .then(() => {
                if (localfeeToSave) {
                    this.save(true);
                }
                else {
                    this.submitFees(false);
                }
            });
    }

    submitFees(savedFeesAlso) {
        this.AgencyContractFees.submitFees({ agencyId: this.agencyContract.agencyId, contractId: this.agencyContract.id })
            .$promise
            .then(() => {
                //this.displaySuccessMessage();
                this.$scope.agencyTerrFeeForm.$setPristine();
                var messageText;

                if (savedFeesAlso) {
                    messageText = 'Thank you for saving and submitting your fees';
                }
                else {
                    messageText = 'Thank you for submitting your fees'
                }

                this.alerts.push({
                    type: 'success',
                    msg: messageText,
                    dismissOnTimeout: 5000
                });

                this.loadContract();
                this.$rootScope.$broadcast('commun:contract-update');
                this.$rootScope.$broadcast('update_menu:');
            }, () => {
                this.displayErrorMessage();
            });
    }

    closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }
}

angular
    .module('app')
    .controller('LocalFeeAgreementController', LocalFeeAgreementController)