import { SectionController } from '../SectionController'
import { ReviewPage } from '../../../services/ReviewService'
import { ItemGroupId } from '../../../models/ItemGroup'
import { ReviewItemId } from '../../../models/ReviewItem'


export class DeclarationsController extends SectionController {

    displayApproveButton: boolean;
    isOfficeUser: boolean;
    isApplicant: boolean;
    medicalUploadState: any;
    criminalUploadState: any;
    page: ReviewPage;
    applicationStatus: string;

    static override $inject = ['$rootScope', '$scope', '$q', 'dialogs',
        'PreExistingMedicalConditionsDetails', 'SmokingDetails', 'CriminalDetails',
        'preExistingMedicalConditions', 'smoking', 'criminal',
        'applicationId', 'review', 'ApproveChanges', 'Auth', 'ReevaluateSection', 'applicationObservable']
    constructor($rootScope: angular.IRootScopeService, $scope: angular.IScope, private $q: ng.IQService, dialogs,
        private PreExistingMedicalConditionsDetails, private SmokingDetails, private CriminalDetails,
        private preExistingMedicalConditions, private smoking, private criminal,
        applicationId, review, private ApproveChanges, Auth, ReevaluateSection, applicationObservable) {
        super($rootScope, $scope, Auth, ReevaluateSection, review, applicationId, ItemGroupId.Declarations, applicationObservable, dialogs)
            

        this.isOfficeUser = Auth.user.isOfficer;
        this.isApplicant = Auth.user.isApplicant;

        this.page = review.getPage(ItemGroupId.Declarations);
        this.displayApproveButton = this.page && this.page.approveChange && this.isOfficeUser;

        $scope.$watch(() => this.$scope.declarationsForm.$dirty, () => {
            this.$dirty = this.$scope.declarationsForm.$dirty
        })

    }
    
    save() {
        this.$scope.$broadcast('submitted', true)

        var promisses = []
        var form = this.$scope.declarationsForm
        

        if (form['PreExistingMedicalConditions'].$dirty) {
            this.preExistingMedicalConditions.metaform = form['PreExistingMedicalConditions'].$metaformVersion
            // preExistingMedicalConditionsConfirmation is considered as invalid when option 'no' is selected
            // angularjs doesn't bind value to model when value is invalid
            // this hack is needed to post false when 'no' is selected otherwise preExistingMedicalConditionsConfirmation is undefined
            if (form['PreExistingMedicalConditions'].preExistingMedicalConditionsConfirmation.$viewValue == false) {
                this.preExistingMedicalConditions.preExistingMedicalConditionsConfirmation = false;
            }
            promisses.push(
                this.PreExistingMedicalConditionsDetails.update(this.preExistingMedicalConditions)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.PreExistingMedicalConditions))
            )
        }

        if (form['SmokingDeclaration'].$dirty) {
            this.smoking.metaform = form['SmokingDeclaration'].$metaformVersion
            promisses.push(
                this.SmokingDetails.update(this.smoking)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.SmokingDeclaration))
            )
        }

        if (form['PoliceRecord'].$dirty) {
            this.criminal.metaform = form['PoliceRecord'].$metaformVersion
            promisses.push(
                this.CriminalDetails.update(this.criminal)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.YourPoliceRecord))
            )
        }
        
            
        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })
            }, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            })
    }

    changesApproved() {

        var user = this.Auth.user
        var data = {
            applicationId: this.applicationId,
            item: ItemGroupId.Declarations,
            author: user.firstName + ' ' + user.lastName
        };

        this.ApproveChanges
            .update({ applicationId: this.applicationId }, data)
            .$promise
            .then(data => {
                if (this.displayApproveButton === true) {
                    this.displayApproveButton = false;
                    this.page.approveChange = false;
                }

                this.alerts.push({
                    type: 'success',
                    msg: 'Approval complete',
                    dismissOnTimeout: 5000
                });
            }, () => {
                this.alerts.push({
                    type: 'danger',
                    msg: 'We are sorry but this form could not be approved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            });
    }
}

angular
    .module('app')
    .controller('DeclarationsController', DeclarationsController)
