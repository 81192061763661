import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { BusinessArea } from '../../../services/AuthService';


class ContactBasicDetailsController {
    contactBasicDetails: any
    showMessageText: boolean = false

    isOfficeUser: number;

    $dirty: boolean = false
    alerts: IAlertConfig[] = []

    isAgencyManagmentAllowed: boolean = false;
    isContactOfficeUser: boolean = false;
    tooltipMessage;

    static $inject = ['$scope', '$q', '$timeout', '$state', 'dialogs', 'contactId', 'ContactBasicDetailsPage', 'ContactBasicDetails', 'Auth', '$window'];
    constructor(private $scope, private $q, private $timeout, private $state, private dialogs, contactId, ContactBasicDetailsPage, private ContactBasicDetails, private Auth, private $window) {
        this.isOfficeUser = Auth.user.isOfficer;
        this.contactBasicDetails = ContactBasicDetailsPage;

        this.isContactOfficeUser = this.contactBasicDetails.role === "OFF";
        this.isAgencyManagmentAllowed = Auth.isInBusinessArea(BusinessArea.AGENCY_MANAGEMENT);
        this.contactBasicDetails.minId = 2;
        this.contactBasicDetails.maxId = 4;
        if(this.isContactOfficeUser){
            this.contactBasicDetails.maxId = 5;
            this.tooltipMessage = "You cannot login as another Office User";
        }

        $scope.$watch(() => this.$scope.contactBasicDetailsForm.$dirty, () => {
            this.$dirty = this.$scope.contactBasicDetailsForm.$dirty
        })
    }

    save() {
        this.$scope.$broadcast('submitted', true);
        var promisses = [];
        this.alerts = [];

        var form = this.$scope.contactBasicDetailsForm;

        if (form['AgencyContactBasicDetails'].$dirty || (form['AgencyContactFeatures'] && form['AgencyContactFeatures'].$dirty) ||
            (form['AgencyContactFeaturesDisabled'] && form['AgencyContactFeaturesDisabled'].$dirty) &&
            (form['AgencyContactFeaturesAndRolesDisabled'] && form['AgencyContactFeaturesAndRolesDisabled'].dirty)) {
            this.contactBasicDetails.metaform = form['AgencyContactBasicDetails'].$metaformVersion;

            this.contactBasicDetails.branchId = this.contactBasicDetails.branchIdValue;

            this.changedForms(form);

            promisses.push(
                this.ContactBasicDetails.saveBasicDetails({ contactId: this.contactBasicDetails.contactId }, this.contactBasicDetails).$promise)
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })
                this.showMessageText = true;
                this.$timeout(() => { this.showMessageText = false; }, 5000);
                this.$window.location.reload();
            }, () => {

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 5000
                })
                this.showMessageText = true;
                this.$timeout(() => { this.showMessageText = false; }, 5000);
            })
    }

    signinAsUser() {
        var usr = this.Auth.user;
        if (usr.isOfficer && !this.isContactOfficeUser) {
            this.dialogs.confirm('Please Confirm', 'Are you sure that you wish to log out and log in as other user?')
                .result
                .then(() => {
                    this.$scope.$broadcast('submitted', true);
                    this.Auth.loginAsUser('INT', this.contactBasicDetails.contactId)
                        .then(() => {
                            this.$state.transitionTo('app.home', {}, { reload: true, inherit: true, notify: true });
                        }, (error) => {
                            this.dialogs[error.type](error.title, error.message);
                        });
                });
        }
    }

    changedForms(form) {
        this.contactBasicDetails.ContactBasicDetailsFieldsChanged = [];

        if (form['AgencyContactBasicDetails'].$dirty) {
            this.contactBasicDetails.ContactBasicDetailsFieldsChanged.push("agencyContactBasicDetailsForm");
        }

        if (form['AgencyContactFeatures'] && form['AgencyContactFeatures'].$dirty) {
            this.contactBasicDetails.ContactBasicDetailsFieldsChanged.push("agencyContactFeaturesForm");
        }
            
        if (form['AgencyContactFeaturesDisabled'] && form['AgencyContactFeaturesDisabled'].$dirty) {
            this.contactBasicDetails.ContactBasicDetailsFieldsChanged.push("agencyContactFeaturesDisabledForm");
        }

        if (form['AgencyContactFeaturesAndRolesDisabled'] && form['AgencyContactFeaturesAndRolesDisabled'].$dirty) {
            this.contactBasicDetails.ContactBasicDetailsFieldsChanged.push("agencyContactFeaturesAndRolesDisabledForm");
        }
    }
}

angular
    .module('app')
    .controller('ContactBasicDetailsController', ContactBasicDetailsController)