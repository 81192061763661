import { IAlertConfig } from '../../models/bootstrap/Alert';

export class AgencyModalController {

    data: any = {};
    alerts: IAlertConfig[] = []

    static $inject = ['$uibModalInstance', '$scope', 'Agency'];
    constructor(private $uibModalInstance, private $scope, private Agency) {
    }

    validate() {
        this.$scope.$broadcast('submitted', true)
        return this.data.$validation();
    } 

    save() {
        if (!this.validate()) {
            return
        }

        this.alerts = [];

        this.Agency.create(this.data).$promise
            .then((agencyId) => {
                this.$uibModalInstance.close(agencyId);
            }, () => {
                    this.alerts.push({
                        type: 'danger',
                        msg: 'We are sorry but agency is not created. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                        dismissOnTimeout: 5000
                    });
            });
    }

    close() {
        this.$uibModalInstance.dismiss('cancel');
    }

}