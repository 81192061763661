import { IAlertConfig } from '../../../models/bootstrap/Alert' 

interface IAirportScope extends angular.IScope {
    airportForm: any
}
export class AirportModalController {

    alerts: IAlertConfig[] = []
    editMode: boolean;
    $dirty: boolean = false;
    airportInfo: any;
        
    static $inject = ['$uibModalInstance', '$scope', 'Airport', 'airportInfo'];
    constructor(private $uibModalInstance, private $scope: IAirportScope, private Airport, airportInfo) {
        this.airportInfo = airportInfo;
        airportInfo.editMode = airportInfo.airportCode ? true : false;
    }

    saveAirport() {
        this.$scope.$broadcast('submitted', true);

        var form = this.$scope.airportForm;
        this.Airport.save(this.airportInfo)
            .$promise.then(
                () => {
                    form.$setPristine();
                    this.close();
                },
                () => {

                    this.alerts.push({
                        type: 'danger',
                        msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                        dismissOnTimeout: 5000
                    });
                }
            );
    }

    close() {
        this.$uibModalInstance.close();
    }
}
