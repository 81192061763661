import { IAlertConfig } from '../../../../models/bootstrap/Alert' 

export class AgencyTerritoryModalController{

    isOfficeUser: boolean = false;

    showMessageText: boolean = false
    alerts: IAlertConfig[] = []

    static $inject = ['$scope', '$uibModalInstance', 'AgencyTerritory', 'agencyTerritory', 'Auth', '$timeout']
    constructor(private $scope, private $uibModalInstance, private AgencyTerritory, private agencyTerritory, Auth, private $timeout) {
        this.isOfficeUser = Auth.user.isOfficer;
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel')
    }

    save() {
        
        this.alerts = [];

        this.AgencyTerritory.save(this.agencyTerritory)
            .$promise.then(() => {
                this.$uibModalInstance.close();
            },
                () => {

                    this.alerts.push({
                        type: 'danger',
                        msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                        dismissOnTimeout: 5000
                    })
                    this.showMessageText = true;
                    this.$timeout(() => { this.showMessageText = false; }, 5000);
                });

    }
}

angular
    .module('app')
    .controller('AgencyTerritoryModalController', AgencyTerritoryModalController)