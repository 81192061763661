import { ReviewService } from "../../../services/ReviewService";
import { ItemGroupId } from "../../../models/ItemGroup";

class ApplicationPrintController {

    applicantHobbies: any;
    aboutYou: any;
    hostFamilyLetter: any;
    availability: any;
    contactDetails: any;
    emergencyContact: any;
    drivingDetails: any;
    prematch: any;
    passport: any;
    visa: any;
    preferences: any;
    previousExperience: any;
    dce: any;
    history: any;
    medical: any;
    criminal: any;


    display;
    ItemGroupId = ItemGroupId

    static $inject = [
        '$scope', 'review', 'applicantHobbies', 'profilePage', 'personalDetailsPage', 'travelAndProgramPage', 'childcareExperiencePage', 'educationAndWorkPage', 'medicalAndCriminalPage',
        'allergies', 'physicalHealth', 'mentalHealth', 'medicalTreatments', 'otherHealthConditions', 'restrictedHealthInformation', 'vaccinations'];
    constructor(
        private $scope, private review: ReviewService, applicantHobbies, profilePage, personalDetailsPage, travelAndProgramPage, childcareExperiencePage, educationAndWorkPage, medicalAndCriminalPage,
        private allergies, private physicalHealth, private mentalHealth, private medicalTreatments, private otherHealthConditions, private restrictedHealthInformation, private vaccinations
    ) {
        this.display = {};
        for (let page of this.review.pages) {
            if (page.menuItem.applicantOrderNum > 0) {
                this.display[page.group] = true;
            }
        }


        this.applicantHobbies = applicantHobbies;
        this.aboutYou = profilePage.aboutYou;
        this.hostFamilyLetter = profilePage.hostFamilyLetter;


        this.availability = personalDetailsPage.availability;
        this.contactDetails = personalDetailsPage.contact;
        this.emergencyContact = personalDetailsPage.emergencyContact;
        this.drivingDetails = personalDetailsPage.driving;

        this.prematch = travelAndProgramPage.prematch;
        this.passport = travelAndProgramPage.passport;
        this.visa = travelAndProgramPage.visa;

        this.preferences = childcareExperiencePage.preferences;
        this.previousExperience = childcareExperiencePage.previousExperience;
        this.dce = childcareExperiencePage.dce;

        this.history = educationAndWorkPage.history;

        this.medical = medicalAndCriminalPage.medical;
        this.criminal = medicalAndCriminalPage.criminal;
    }
        
}

angular
    .module('app')
    .controller('ApplicationPrintController', ApplicationPrintController);