import { IAlertConfig } from '../../../models/bootstrap/Alert' 

export class MaintainCountryController {

    countries
    country

    countrySettings = {}
    financialDetails = {}

    alerts: IAlertConfig[] = []

    static $inject = ['$scope', '$q', 'Spinner', 'ApplicationLookup', 'CountrySettings', 'PaymentOptions', 'dialogs', '$uibModal'];
    constructor(private $scope, private $q, private Spinner, private ApplicationLookup, private CountrySettings, private PaymentOptions, private dialogs, private $uibModal) {
        ApplicationLookup.country().$promise.then((data) => {
            this.countries = data;
        });
            
    }

    countrySelected(newValue) {

        this.CountrySettings.get({ countryCode: newValue.code }).$promise.then((data) => {
            this.countrySettings = data;
        });

        this.PaymentOptions.byCountry({ countryCode: newValue.code }).$promise.then((data) => {
            this.financialDetails = data;
        });
    }

        
    save() {
        this.$scope.$broadcast('submitted', true)

        var promisses = []
        var form = this.$scope.maintainCountryForm
        if (form['CountryBasicDetails'].$dirty || form['CountryVisaDetails'].$dirty) {
            promisses.push(this.CountrySettings.save(this.countrySettings).$promise);
        }
        if (form['CountryFinancialDetails'].$dirty) {
            promisses.push(this.PaymentOptions.save(this.financialDetails).$promise);
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine()
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })
            }, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            })


        //this.Spinner.show();

            

    }

        


    public closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }
}


angular
    .module('app')
    .controller('MaintainCountryController', MaintainCountryController);
