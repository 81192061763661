import { IAlertConfig } from '../../../models/bootstrap/Alert' 

export class AddBranchModalController {
    alerts: IAlertConfig[] = []

    static $inject = ['$uibModalInstance', '$scope', 'Branches', 'branch'];
    constructor(private $uibModalInstance, private $scope, private Branches, private branch) {
    }

    validate() {
        this.$scope.$broadcast('submitted', true)
        return this.branch.$validation();
    }

    save() {
        if (!this.validate()) {
            return
        }

        this.alerts = [];

        this.Branches.save(this.branch).$promise
            .then(() => {
                this.$uibModalInstance.close();
            }, () => {
                this.alerts.push({
                    type: 'danger',
                    msg: 'We are sorry but agency is not created. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 5000
                });
            });
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }


}

