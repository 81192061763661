import moment from 'moment';
import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { EditApplicationController } from './EditApplicationController';

class EditPersonalDetailsController extends EditApplicationController{

    contactDetails: any;
    emergencyContact: any;
    drivingDetails: any;

    //applicationId: number
    //applicantUserId: number
    //applicationAgencyId: number
    //applicationInterviewerId: number

    //$dirty: boolean = false

    //alerts: IAlertConfig[] = []

    static override $inject = ['$scope', '$q', 'applicationId', 'personalDetailsPage', 'ContactDetails', 'Driving', 'EmergencyContact','ApplicationReviewItemsAny'];
    constructor($scope, private $q, applicationId, personalDetailsPage, private ContactDetails, private Driving, private EmergencyContact, ApplicationReviewItemsAny) {
        super($scope, applicationId, ApplicationReviewItemsAny);            

        var getCountry = () => this.contactDetails.country;

        this.contactDetails = personalDetailsPage.contact;
        this.contactDetails.getCountry = getCountry;

        this.emergencyContact = personalDetailsPage.emergencyContact;
        this.emergencyContact.getCountry = getCountry

        this.drivingDetails = personalDetailsPage.driving;
        this.drivingDetails.currentDate = moment().startOf('day').toDate();

        $scope.$watch(() => this.$scope.personalDetailsForm.$dirty, () => {                
            this.$dirty = this.$scope.personalDetailsForm.$dirty
        })
    }

    save() {
        this.$scope.$broadcast('submitted', true);
        var promisses = [];
        this.alerts = [];

        var form = this.$scope.personalDetailsForm;

        if (form['Edit_ContactDetails'].$dirty) {
            this.contactDetails.metaform = form['Edit_ContactDetails'].$metaformVersion;
            promisses.push(
                this.ContactDetails.update(this.contactDetails).$promise);
        }

        if (form['Edit_DrivingDetails'].$dirty) {
            this.drivingDetails.metaform = form['Edit_DrivingDetails'].$metaformVersion;
            promisses.push(
                this.Driving.update(this.drivingDetails).$promise);
        }

        if (form['Edit_EmergencyContactDetails'].$dirty) {
            this.emergencyContact.metaform = form['Edit_EmergencyContactDetails'].$metaformVersion;
            promisses.push(
                this.EmergencyContact.update(this.emergencyContact).$promise);
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })

                this.CheckAnyReviewPending(this.applicationId);
            }, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            })
    }
}

angular
    .module('app')
    .controller('EditPersonalDetailsController', EditPersonalDetailsController)